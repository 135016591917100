<template>
    <div class="mainpage">
    
        <div class="fullver">
            <nav class="navbar navbar-expand-lg navbar-light fixed-top" style="background: #fff;">
                <div class="container">
                    <div class="d-flex justify-content-between w-100 my-3 text-bg-light navbar-mobile">
                        <div class="d-flex">
                            <a class="navbar-brand fw-bold my-auto tapwillimg pe-2 pe-sm-3 me-0" href="/#" style="font-family: 'Unbounded', sans-serif;">TapWill</a>
                            <div class="stats d-flex flex-column justify-content-start ps-2 ps-sm-3">
                                <p class="my-auto navbar-brand-text">Статистика и аналитика товаров <br> <span class = 'Wildberries'> Wildberries </span>, <span class = 'Ozon'> Ozon </span> и <span style="color: #1E1E1E;">Яндекс.Маркет</span> </p>
                            </div>
                        </div>
                        
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon d-flex"></span>
                        </button>
                    </div>
                    <div class="header_right ms-lg-auto ms-2">
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav flex-row my-3">
                            <!-- <li class="nav-item my-auto">
                            <a class="nav-link fw-semibold" href="#about_us">О сервисе</a>
                            </li>
                            <li class="nav-item my-auto">
                            <a class="nav-link fw-semibold" href="#tariff">Подписка</a>
                            </li>
                            <li class="nav-item my-auto">
                            <a class="nav-link fw-semibold" href="#faq">FAQ</a>
                            </li> -->
                            <li class="nav-item my-auto ">
                                <a class="nav-link fw-semibold d-flex" href="/auth">
                                <img src="../assets/images/main/iconamoon_enter-fill.png" alt="">
                                <span class="my-auto">Войти</span> 
                            </a>
                            </li>
                            <li class="nav-item my-auto">
                            <a class="nav-link btn-gradient d-flex" href="/reg" style="color: #fff;">Зарегистрироваться</a> 
                            </li>
                        </ul>
                    </div>
                  </div>
                </div>
            </nav>
            <main class = 'main_glav'>
                <div class="main_1"> 
                    <div class="maincont container">
                        <h1 class="main_text">Учет финансов <br> для роста прибыли <br class="d-lg-block d-none"> на маркетплейсах <br> от 300% в год</h1>
                        <!-- <div class="under_texxt_strong d-flex flex-column lh-1 w-100 mt-3">
                          <h2 style="color: #fff; font-weight: 800; text-align: center;">Масштабируйте свой бизнес на Ozon и Wildberries <br> вместе с аналитикой от Tapwill!</h2>
                        </div> -->
                        <div class="under_texxt lh-1">
                          <p style="color: #fff;"><b>Получите 14 дней доступа в сервис аналитики <br> TapWill </b> и аудит вашего бренда бесплатно!</p>
                        </div>
                        <div class="regg-container"> 
                            <a href="/reg" class="btn-gradient btn-white" style="font-size: 20px; color: #521D89;"> Попробовать бесплатно </a>
                        </div>
                    </div>
                </div> 

                <div id="for_whom"></div>

                <section class="section-block">
                    <div class="container">
                        <h2>С нами легче работать</h2>

                        <div class="d-lg-flex justify-content-between main-block-2-blocks">

                            <div class="main-block-2-block" style="background: linear-gradient(90deg, #9c55d1 0%, #44157e 100%);;">
                                <h4 style="color: #f4f4f4;">Новичкам</h4>
                                <p style="color: #f4f4f4;">Быстро определяют эффективную финансовую модель и понимают, какой товар усилить рекламой, а какой распродать.</p>
                                <img src="../assets/images/main/main_block_for_beginners.png" alt="" style="margin-top: auto;">
                            </div>

                            <div class="main-block-2-block">
                                <h4>Действующим селлерам</h4>
                                <p>Контролируют каждый рубль прибыли, эффективность рекламных кампаний и в короткие сроки принимают решение об участии в акциях, даже огромным ассортиментом.</p>
                                <img src="../assets/images/main/main_block_for_sellers.png" alt="">
                            </div>

                            <div class="main-block-2-block">
                                <h4>Крупным компаниям</h4>
                                <p>Оптимизируют работу и выходят в лидеры рынка за счёт автоматизации отгрузок, контроля оборачиваемости и автоматического сбора отчетности об эффективности всех инструментов.</p>
                                <img src="../assets/images/main/main_block_for_companies.png" style="margin-top: auto;" alt="">
                            </div>

                        </div>
                    </div>
                    
                </section>

                <section class="section-block">
                    <div class="mx-lg-5 main-block-3-wrapper">
                    <div class="container main-block-3">
                        <div class="row">
                            <div class="col-lg-4">
                                <h2 class="mt-0">Мы полезны на каждом этапе</h2>
                                <p style="padding-bottom: 50px;" class="main-block-3-p">Tapwill предоставляет комплексный взгляд на все элементы работы магазина и позволяет влиять на них.</p>
                                <a href="/reg" class="btn-gradient" style="font-size: 20px;"> Попробовать бесплатно </a>
                            </div>
                            <div class="col-lg-8">
                                    <div class="d-lg-flex justify-content-between">
                                    <div class="main-block-2-block">
                                        <img src="../assets/images/main/block_3_1.svg" alt="">
                                        <h4>Быстрый рост</h4>
                                        <p>Набирай обороты с помощью отчетов в личном кабинете, расчета участия в акциях и грамотного распределения по складам</p>
                                    </div>

                                    <div class="main-block-2-block">
                                        <img src="../assets/images/main/block_3_2.svg" alt="">
                                        <h4>Выход из кризиса</h4>
                                        <p>Инструменты TapWill помогут найти и излечить болезни роста и оптимизировать прибыль</p>
                                    </div>
                                    </div>

                                    <div class="d-lg-flex justify-content-between">
                                    <div class="main-block-2-block">
                                        <img src="../assets/images/main/block_3_3.svg" alt="">
                                        <h4>Эффективное продвижение</h4>
                                        <p>Обойди конкурентов и займи лидирующие позиции с помощью контроля статистики рекламных инструментов, SEO и искусственного интеллекта</p>
                                    </div>

                                    <div class="main-block-2-block">
                                        <img src="../assets/images/main/block_3_4.svg" alt="">
                                        <h4>Масштабирование</h4>
                                        <p>Автоматизируй рутинные процессы и направь внимание на действительно важные задачи</p>
                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>

                <div id="benefits"></div>

                <section class="section-block">
                    <div class="main-block-4">
                        <div class="container">
                            <h2>Почему выбирают нас?</h2>

                            <div class="row">

                                <div class="col-lg-3 d-sm-flex d-lg-block">
                                    <div class="main-block-2-block me-4 me-lg-0">
                                        <h4>Точность данных</h4>
                                        <p>Данные в отчетах сервиса полностью совпадают с данными ваших магазинов на маркетплейсах.</p>
                                        <img src="../assets/images/main/block_4_1.svg" alt="">
                                    </div>

                                    <div class="main-block-2-block d-lg-none d-flex">
                                        <h4>Легкость освоения</h4>
                                        <p>Наши отчеты просты и удобны. Все отчеты имеют подробные инструкции и видео, помогающие легко читать и использовать.</p>
                                        <img class="px-5" src="../assets/images/main/block_4_2.svg" alt="">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="main-block-2-block mb-3" style="padding-bottom: 45px;">
                                        <h4>Большой функционал</h4>
                                        <p class="mb-0">Полный набор инструментов для внутренней аналитики для Wildberries и Ozon. Более 10 полезных отчетов с удобными настраиваемыми фильтрами и параметрами.</p>
                                        <a href="/reg" class="btn-gradient mx-auto" style="font-size: 20px; width: fit-content; margin-top: 50px;"> Подключиться к TapWill </a>
                                    </div>
                                    <div class="main-block-2-block" style="background: linear-gradient(90deg, #9c55d1 0%, #44157e 100%);;">
                                        <h4 style="color: #f4f4f4;">Подходит всем</h4>
                                        <p style="color: #f4f4f4;">TapWill создан селлерами для селлеров. Наш опыт в товарном бизнесе и разработке IT систем позволил создать полезный и удобный продукт, поэтому он подойдет как для новичков, так и для опытных селлеров.</p>
                                        <img class="mx-auto" src="../assets/images/main/block_4_3.svg" alt="">
                                    </div>
                                </div>

                                <div class="col-lg-3 d-none d-lg-block">
                                    <div class="main-block-2-block">
                                        <h4>Легкость освоения</h4>
                                        <p>Наши отчеты просты и удобны. Все отчеты имеют подробные инструкции и видео, помогающие легко читать и использовать.</p>
                                        <img class="px-5" src="../assets/images/main/block_4_2.svg" alt="">
                                    </div>
                                </div>

                            </div>

                            <div id="oportunities"></div>

                            <h2>Возможности сервиса Tapwill</h2>
                        </div>
                        <div class="main-block-4-2 mx-sm-5 mx-3">
                            <div class="main-block-4-2-button-block d-none d-lg-flex justify-content-between">
                                <a :class="{'active': showService == 0}" @click="showService = 0">Финансовая аналитика</a>
                                <a :class="{'active': showService == 1}" @click="showService = 1">Сводка по товарам</a>
                                <a :class="{'active': showService == 2}" @click="showService = 2">Рекламная аналитика</a>
                                <a :class="{'active': showService == 3}" @click="showService = 3">Статистика</a>
                            </div>
                            <div class="main-block-4-2-button-block d-flex d-lg-none justify-content-between pt-3 px-3" style="border-radius: 30px; border-bottom-left-radius: 0; border-bottom-right-radius: 0;">
                                <a :class="{'active': showService == 0}" @click="showService = 0">Финансовая аналитика</a>
                                <a :class="{'active': showService == 1}" @click="showService = 1">Сводка по товарам</a>
                            </div>
                            <div class="main-block-4-2-button-block d-flex d-lg-none justify-content-between pb-3 px-3" style="border-radius: 30px; border-top-left-radius: 0; border-top-right-radius: 0;">
                                <a :class="{'active': showService == 2}" @click="showService = 2">Рекламная аналитика</a>
                                <a :class="{'active': showService == 3}" @click="showService = 3">Статистика</a>
                            </div>
                            <div class="main-block-4-2-content row">
                                <div class="content-list col-lg-6 d-flex flex-column justify-content-center">
                                    <h4>{{ services[showService].header }}</h4>
                                    <p v-for="item in services[showService].list"><img src="../assets/images/main/check_icon.svg" alt="">{{ item }}</p>
                                </div>
                                <div class="content-image col-lg-6">
                                    <img src="../assets/images/main/main_block_finance_analitics.png" v-if="showService == 0" class="w-100" alt="">
                                    <img src="../assets/images/main/main_block_statistics.png" v-if="showService == 3" class="w-100" alt="">
                                    <img src="../assets/images/main/main_block_adv.png" v-if="showService == 2" class="w-100" alt="">
                                    <img src="../assets/images/main/main_block_pnl.png" v-if="showService == 1" class="w-100" alt="">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section-block">
                    <div class="container main-block-5">
                        <h2>Переходите из таблиц в TapWill</h2>
                        <p style="color: #1E1E1E; margin-bottom: 50px;">Перестаньте сводить цифры из разных отчетов руками в таблицах, искать <br> ошибки и анализировать неактуальные отчеты</p>
                    
                        <div class="d-lg-flex justify-content-between w-100">
                            <div class="main-block-2-block">
                                <h4>Эксель и Гугл-таблицы</h4>
                                <p>Вручную собираете операции из финансовых и аналитических отчетов, переносите их в таблицы, а потом долго ищете, где ошибка в формуле?</p>
                                <img src="../assets/images/main/block_5_1.svg" alt="">
                            </div>
                            <div class="main-block-2-block" style="background: linear-gradient(90deg, #9c55d1 0%, #44157e 100%);;">
                                <h4 style="color: #f4f4f4;">TapWill</h4>
                                <p style="color: #f4f4f4;">Автоматически загружает всю статистику по заказам и продажам, финансовые отчеты, операции и расходы - надежно и точно.</p>
                                <img src="../assets/images/main/block_5_2.svg" alt="">
                            </div>
                        </div>
                    </div>
                </section>

                <div id="get_file"></div>

                <section class="section-block">
                    <div class="mx-lg-5 main-block-3-wrapper py-0">
                    <div class="container main-block-3">
                        <div class="row">
                            <div class="col-lg-7 content-block" style="padding-top: 100px; padding-bottom: 100px;">
                                <h2 style="margin-top: 0 !important;">Оптимизируйте свой бизнес уже сегодня 
                                    с пробным периодом в 14 дней!</h2>
                                <p style="padding-bottom: 50px;">А так же специально для вас мы подготовили файл ТОП-10 неочевидных ошибок при ведении бизнеса на маркетплейсах, которые могут стоить вам годы работ.</p>
                                <!-- <a href="/docs/mistakes.pdf" download="Топ 10 ошибок.pdf" onclick="setTimeout(()=>window.location.href = '/reg', 1000)" class="btn-gradient" style="font-size: 20px;"> Получить файл и подключить магазин </a> -->
                                <a href="https://t.me/tapwill_guide_bot" class="btn-gradient" style="font-size: 20px;"> Получить файл и подключить магазин </a>
                                
                            </div>
                            <div class="col-lg-5 d-flex">
                                <img src="../assets/images/main/block_6_1.svg" alt="" class="mt-auto phone-img">
                            </div>
                        </div>
                    </div>
                    </div>
                </section>

                <div id="prices"></div>

                <section class="section-block">
                    <div class="container main-block-6">
                        <h2>Стоимость дальнейшей подписки на месяц, при суммарном обороте по заказам за 31 день:</h2>

                        <div class="d-lg-flex justify-content-between">

                            <div class="main-block-2-block" style="background: linear-gradient(90deg, #9c55d1 0%, #44157e 100%);;">
                                <h4 style="color: #f4f4f4;">Старт и первые продажи</h4>
                                <p style="color: #f4f4f4;">Поможет познакомиться с сервисом, выбрать нишу для продаж, проанализировать конкурентов</p>
                                <div class="tariff-price d-flex my-4">
                                    <div class="me-5">
                                        <p style="font-size: 20px; color: #f4f4f4;" class="mb-1 tariff-price-header-p">Оборот магазина</p>
                                        <p style="font-weight: 600; color: #f4f4f4;">до 900 тыс/руб</p>
                                    </div>
                                    <div>
                                        <p style="font-size: 20px; color: #f4f4f4;" class="mb-1 tariff-price-header-p">Цена</p>
                                        <p style="font-weight: 600; color: #f4f4f4;">Бесплатно</p>
                                    </div>
                                </div>
                                <a href="/reg" class="btn-gradient btn-white w-100" style="font-size: 20px; color: #521D89;"> Подключить </a>
                            </div>

                            <div class="main-block-2-block">
                                <h4>Стабильные продажи</h4>
                                <p>Поможет познакомиться с сервисом, выбрать нишу для продаж, проанализировать конкурентов</p>
                                <div class="tariff-price d-flex my-4">
                                    <div class="me-5">
                                        <p style="font-size: 20px;" class="mb-1 tariff-price-header-p">Оборот магазина</p>
                                        <p style="font-weight: 600; color: #1E1E1E;">900 тыс - 1,5 млн</p>
                                    </div>
                                    <div>
                                        <p style="font-size: 20px;" class="mb-1 tariff-price-header-p">Цена</p>
                                        <p style="font-weight: 600; color: #1E1E1E;">990 руб/мес</p>
                                    </div>
                                </div>
                                <a href="/reg" class="btn-gradient w-100" style="font-size: 20px;"> Подключить </a>
                            </div>

                            <div class="main-block-2-block">
                                <h4>Активные продажи</h4>
                                <p>Поможет познакомиться с сервисом, выбрать нишу для продаж, проанализировать конкурентов</p>
                                <div class="tariff-price d-flex my-4">
                                    <div class="me-5">
                                        <p style="font-size: 20px;" class="mb-1 tariff-price-header-p">Оборот магазина</p>
                                        <p style="font-weight: 600; color: #1E1E1E;">1,5 млн - 3 млн</p>
                                    </div>
                                    <div>
                                        <p style="font-size: 20px;" class="mb-1 tariff-price-header-p">Цена</p>
                                        <p style="font-weight: 600; color: #1E1E1E;">2.990 руб/мес</p>
                                    </div>
                                </div>
                                <a href="/reg" class="btn-gradient w-100" style="font-size: 20px;"> Подключить </a>
                            </div>

                            </div>

                            <div class="d-lg-flex justify-content-between mt-2">

                                <div class="main-block-2-block">
                                    <h4>Продвинутый</h4>
                                    <p>Поможет познакомиться с сервисом, выбрать нишу для продаж, проанализировать конкурентов</p>
                                    <div class="tariff-price d-flex my-4">
                                        <div class="me-5">
                                            <p style="font-size: 20px;" class="mb-1 tariff-price-header-p">Оборот магазина</p>
                                            <p style="font-weight: 600; color: #1E1E1E;">3 млн - 5 млн</p>
                                        </div>
                                        <div>
                                            <p style="font-size: 20px;" class="mb-1 tariff-price-header-p">Цена</p>
                                            <p style="font-weight: 600; color: #1E1E1E;">4.990 руб/мес</p>
                                        </div>
                                    </div>
                                    <a href="/reg" class="btn-gradient w-100" style="font-size: 20px;"> Подключить </a>
                                </div>

                                <div class="main-block-2-block" style="background: linear-gradient(90deg, #9c55d1 0%, #44157e 100%);;">
                                    <h4 style="color: #f4f4f4;">Профессиональный</h4>
                                    <p style="color: #f4f4f4;">Поможет познакомиться с сервисом, выбрать нишу для продаж, проанализировать конкурентов</p>
                                    <div class="tariff-price d-flex my-4">
                                        <div class="me-5">
                                            <p style="font-size: 20px; color: #f4f4f4;" class="mb-1 tariff-price-header-p">Оборот магазина</p>
                                            <p style="font-weight: 600; color: #f4f4f4;">5 млн - 10 млн</p>
                                        </div>
                                        <div>
                                            <p style="font-size: 20px; color: #f4f4f4;" class="mb-1 tariff-price-header-p">Цена</p>
                                            <p style="font-weight: 600; color: #f4f4f4;">9.990 руб/мес</p>
                                        </div>
                                    </div>
                                    <a href="/reg" class="btn-gradient btn-white w-100" style="font-size: 20px; color: #521D89;"> Подключить </a>
                                </div>

                                <div class="main-block-2-block">
                                    <h4>VIP уровень</h4>
                                    <p>Поможет познакомиться с сервисом, выбрать нишу для продаж, проанализировать конкурентов</p>
                                    <div class="tariff-price d-flex my-4">
                                        <div class="me-5">
                                            <p style="font-size: 20px;" class="mb-1 tariff-price-header-p">Оборот магазина</p>
                                            <p style="font-weight: 600; color: #1E1E1E;">более 10 млн</p>
                                        </div>
                                        <div>
                                            <p style="font-size: 20px;" class="mb-1 tariff-price-header-p">Цена</p>
                                            <p style="font-weight: 600; color: #1E1E1E;">19.990 руб/мес</p>
                                        </div>
                                    </div>
                                    <a href="/reg" class="btn-gradient w-100" style="font-size: 20px;"> Подключить </a>
                                </div>

                            </div>
                    </div>
                </section>

                <div id="reviews"></div>

                <section class="section-block">
                    <div class="main-block-7">
                        <div class="container pe-0 me-0">
                            <div class="d-flex justify-content-between mb-5">
                                <div>
                                    <h2>Отзывы селлеров</h2>
                                    <p>Вот что клиенты говорят о своем опыте использования сервисов <br> аналитики маркетплейсов от компании TapWill</p>
                                </div>
                                <div class="slider-arrows d-flex">
                                    <img src="../assets/images/main/block_7_arrow_left.svg" class="me-3" id="slick-arrow-prev" alt="">
                                    <img src="../assets/images/main/block_7_arrow_right.svg" id="slick-arrow-next" alt="">
                                </div>
                            </div>
                            
                            <div class="reviews-slider w-100">

                                <div class="main-block-2-block">
                                    <div class="d-flex review-header mb-5">
                                        <img src="../assets/images/main/vlad_review.jpg" style="width: 160px;" alt="">
                                        <div class="my-auto ms-4 w-100">
                                            <h4 class="text-start">Влад Кутицын</h4>
                                            <p class="mb-0">Опыт работы с маркетплейсами: 1 год</p>
                                        </div>
                                        
                                    </div>

                                    <p>Работаем с сервисом Tapwill уже 3 месяца, нравится работа с блоком акций, оборачиваемости и рекламы - позволяет очень быстро принимать решения об управлении ассортиментом. За время работы, наша прибыль увеличилась на 6% за счет переработки стратегии работы с акциями и поставками.</p>

                                </div>

                                <div class="main-block-2-block">
                                    <div class="d-flex review-header mb-5">
                                        <img src="../assets/images/main/anton_review.jpg" style="width: 160px;" alt="">
                                        <div class="my-auto ms-4 w-100">
                                            <h4 class="text-start">Антон Вязов</h4>
                                            <p class="mb-0">Опыт работы с маркетплейсами: 1 год</p>
                                        </div>
                                        
                                    </div>

                                    <p>Продаем на Wildberries и Яндекс Маркет в категории одежды и сервис стал для нас настоящим спасением для сбора сводной PNL отчетности между площадками. Команда сервиса быстро реагировала на наши вопросы, помогла разобраться чтением финансово отчетности и дала рекомендации на какие показатели обратить внимание и контролировать. </p>

                                </div>

                                <div class="main-block-2-block">
                                    <div class="d-flex review-header mb-5">
                                        <img src="../assets/images/main/review_img_1.png" style="width: 160px;" alt="">
                                        <div class="my-auto ms-4 w-100">
                                            <h4 class="text-start">Александр Перков</h4>
                                            <p class="mb-0">Опыт работы с маркетплейсами: 1 год</p>
                                        </div>
                                        
                                    </div>

                                    <p>Продаю женскую одежду на Wildberries. Ценность аналитики хорошо понимаю. Раньше пользовался другим сервисом аналитики, но там мне все время не хватало возможностей и инструментов. Да и в точности данных были сомнения: заметил, что они сильно расходятся с моими собственными подсчётами.
                                        Цифры, которые я получаю в Moneyplace, кажутся точнее...</p>

                                </div>

                            </div>
                        </div>
                    </div>
                                        
                </section>

                <div id="faq"></div>

                <section class="section-block">
                    <div class="main-block-9">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-6 main-block-9-left">
                                    <h2>Частые вопросы клиентов</h2>
                                    <div class="w-100 mt-auto d-none d-lg-block" style="position: relative;">
                                        <img src="../assets/images/main/block_8_1.svg" class="w-100" alt="">
                                        <a href="/reg" class="btn-gradient btn-white" style="font-size: 20px; color: #521D89; position: absolute; text-align: center; bottom: 40px; left: 10%; right: 10%;"> Подключить </a>
                                    </div>
                                </div>
                                <div class="col-lg-6">

                                    <div class="faq-item">
                                        <div class="d-flex justify-content-between" @click="openAnswer(1)">
                                            <p class="my-auto">Сколько я могу пользоваться своими данными?</p>
                                            <img src="../assets/images/main/block_9_1.svg" alt="" v-if="answerOpened !== 1">
                                            <img src="../assets/images/main/block_9_2.svg" v-else alt="">
                                        </div>
                                        <p class="mt-5 faq-item-answer" v-if="answerOpened == 1">Вы можете пользоваться своими данными в любое время, пока у вас активен один из наших тарифов.</p>
                                    </div>

                                    <div class="faq-item">
                                        <div class="d-flex justify-content-between" @click="openAnswer(2)">
                                            <p class="my-auto">У WildBerries регулярно выходят изменения АРІ, как быстро выходят обновления в вашем сервисе?</p>
                                            <img src="../assets/images/main/block_9_1.svg" alt="" v-if="answerOpened !== 2">
                                            <img src="../assets/images/main/block_9_2.svg" v-else alt="">
                                        </div>
                                        <p class="mt-5 faq-item-answer" v-if="answerOpened == 2">Мы регулярно в автоматизированном режиме следим за новостями маркетплейса, а команда IT-специалистов всегда готовы к любым изменениям. </p>
                                    </div>

                                    <div class="faq-item">
                                        <div class="d-flex justify-content-between" @click="openAnswer(3)">
                                            <p class="my-auto">Чем вы отличаетесь от других систем аналитики?</p>
                                            <img src="../assets/images/main/block_9_1.svg" alt="" v-if="answerOpened !== 3">
                                            <img src="../assets/images/main/block_9_2.svg" v-else alt="">
                                        </div>
                                        <p class="mt-5 faq-item-answer" v-if="answerOpened == 3">Мы специализируемся на внутренней финансовой аналитике, поэтому данные у нас более точные и полезные. Кроме того наш сервис был создан и протестирован селлерами, основываясь на актуальных потребностях.</p>
                                    </div>

                                    <div class="faq-item">
                                        <div class="d-flex justify-content-between" @click="openAnswer(4)">
                                            <p class="my-auto">Если я новичок и у меня еще мало товаров, мне подойдет ваш сервис?</p>
                                            <img src="../assets/images/main/block_9_1.svg" alt="" v-if="answerOpened !== 4">
                                            <img src="../assets/images/main/block_9_2.svg" v-else alt="">
                                        </div>
                                        <p class="mt-5 faq-item-answer" v-if="answerOpened == 4">Оцифровка бизнес-процессов - насущная проблема на пути к построению успешного бизнеса, именно эту проблему помогает решить наш сервис. Если у вас возникают сомне ия о полезности сервиса для вашего бизнеса, то вы можете воспользоваться бесплатным 14-дневным доступам.</p>
                                    </div>

                                    <div class="faq-item">
                                        <div class="d-flex justify-content-between" @click="openAnswer(5)">
                                            <p class="my-auto">Безопасно ли передавать вам необходимые API ключи?</p>
                                            <img src="../assets/images/main/block_9_1.svg" alt="" v-if="answerOpened !== 5">
                                            <img src="../assets/images/main/block_9_2.svg" v-else alt="">
                                        </div>
                                        <p class="mt-5 faq-item-answer" v-if="answerOpened == 5">Абсолютно. Забота о клиенте первостепенная задача нашего сервиса. Все данные надежно надежно хранятся на дата-центрах с особыми протоколами безопасности. А все сотрудники имеющие доступ к дата-центрам находятся под договором о неразглашении.</p>
                                    </div>

                                    <div class="faq-item">
                                        <div class="d-flex justify-content-between" @click="openAnswer(6)">
                                            <p class="my-auto">Столкнулся с ошибкой / Есть идея по улучшению функционала, как с вами связаться?</p>
                                            <img src="../assets/images/main/block_9_1.svg" alt="" v-if="answerOpened !== 6">
                                            <img src="../assets/images/main/block_9_2.svg" v-else alt="">
                                        </div>
                                        <p class="mt-5 faq-item-answer" v-if="answerOpened == 6">Мы уважаем пользователей нашего сервиса и считаем, что для этого необходимо иметь с вами обратную связь. По любым интересующим вас вопросам вы можете обратиться на почту <a style="width: fit-content; display: inline;" href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот  <a href="https://t.me/tapwill_support_bot" style="width: fit-content; display: inline;">@tapwill_support_bot</a> </p>
                                    </div>

                                </div>

                                <div class="w-100 d-block d-lg-none mt-5" style="position: relative;">
                                    <img src="../assets/images/main/block_8_1.svg" class="w-100" alt="">
                                    <a href="/reg" class="btn-gradient btn-white" style="font-size: 20px; color: #521D89; position: absolute; text-align: center; bottom: 40px; left: 10%; right: 10%;"> Подключить </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
        </main>
    
        </div>
    </div>
    </template>
    
    <style lang="scss" scoped>
    @import '../assets/style/mainpagenew.scss';
    </style>
    
    <script>
    export default{
        data(){
            return{
                showService: 0,
                services: [
                    {
                        header: 'Инструменты для финансовой аналитики',
                        list: [
                            'Отчет о прибылях и убытках (P&L)',
                            'Точный расчет расходов внутри маркетплейса',
                            'ABC анализ',
                            'Сводка продаж',
                            'Учет себестоимостей'
                        ]
                    },
                    {
                        header: 'Товарная аналитика',
                        list: [
                            'Актуальные данные об остатках',
                            'Аналитические расчеты по артикулам',
                            'Прогноз складского дефицита',
                            'География заказов и продаж',
                            'Информация о статусе отправлений'
                        ]
                    },
                    {
                        header: 'Аналитика продвижения',
                        list: [
                            'Журнал статистики по рекламным кампаниям',
                            'Статистика продвижения по артикулам',
                            'Статистика расходов на рекламу по дням',
                            'Аналитические инструменты (cpa, ctr, cr, cpc, cpm, ДРР, ...)',
                            'Отслеживание статусов рекламных кампаний'
                        ]
                    },
                    {
                        header: 'Статистика магазинов',
                        list: [
                            'Дашборд с основными показателями',
                            'Отчеты по заказам и продажам',
                            'Аналитические инструменты',
                            'Точный расчет операционных расходов',
                            'Регулярная актуализация данных'
                        ]
                    },
                ],

                answerOpened: 0
            }
        },

        methods: {
            openAnswer(n){
                if(this.answerOpened !== n){
                    this.answerOpened = n
                }
                else{
                    this.answerOpened = 0
                }
            }
        },

        mounted(){
            $('.reviews-slider').slick({
                infinite: false,
                slidesToShow: 1,
                //slidesToScroll: 1,
                centerMode: false,
                adaptiveHeight: false,
                variableWidth: true,
                nextArrow: document.querySelector('#slick-arrow-next'),
                prevArrow: document.querySelector('#slick-arrow-prev')
            });
				
        }
    }
    </script>