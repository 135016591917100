<template>
      <div class="container-fluid">
        
        <div class="alert alert-warning" role="alert">
  <p class="mb-0" style="font-size: 0.9em;" v-if="$store.state.user_info.check == 2">Добавьте API ключ чтобы получить 14 дней бесплатного теста сервиса и аудит магазина от рекомендованного эксперта Tapwill - Laba Market!</p>
        <p class="mb-0" style="font-size: 0.9em;" v-else>Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
              </div>
        
        <div class="content-block">
          <div class="accordion d-lg-none" id="filterOpen">
            <div class="accordion-item border-0">
                <h2 class="accordion-header">
                <button class="accordion-button collapsed ps-0" style="width: fit-content;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-controls="collapseOne">
                   <span class="pe-3">Показать параметры</span>
                 </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#filterOpen">
                <div class="accordion-body">
          <form @submit.prevent="getInfo" class="js-pageFilter__form d-flex">
            <div class="row">
              <div class="col-md js-pageFilter__daterangepicker mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="dashboard_period" class="form-label">Период</label>
                <div class="input-group">
                  <input type="text" class="form-control pageFilter__daterange js-daterangepicker" id="dashboard_period_mob" name="dates" autocomplete="off"><span class="input-group-text"><label for="dashboard_period_mob"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
                <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="24.01.2024">
                <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="22.02.2024">
              </div>
              <div class="col-md mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <!-- <label for="shopDashboard" class="form-label">Магазин</label>
                <select v-model="filter.stores" id="shopDashboard" class="form-select" >
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select> -->

                <div class="dropdown">
                  <label class="form-label">Магазин</label>
                  <button class="dropdown-select-button" data-bs-toggle="dropdown">
                    <span v-if="filter.stores.length == 0">Выберите магазины </span v-else><span>{{ filter.stores.join(', ') }}</span>&nbsp;&nbsp;&nbsp;
                  </button>

                  <ul class="dropdown-menu dropdown-select-list">
                    <li v-for="item in stores">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="item.title" v-model="filter.stores" :id="`store_${item.title}`">
                        <label class="form-check-label" :for="`store_${item.title}`">
                          {{ item.title }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brandDashboard" class="form-label">Бренд</label>
                <select v-model="filter.brands" id="brandDashboard" class="form-select" >
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace" >
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
<option>yandex</option>
                  
                </select>
              </div>
              <div class="col-md mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12 d-flex">
                <button class="btn btn-primary mt-auto" @click="applyFilter">Применить</button>
              </div>
            </div>
          </form>
          </div>
          </div>
          </div>
          </div>

          <form @submit.prevent="getInfo" class="js-pageFilter__form d-lg-flex d-none">
            <div class="row">
              <div class="col-md js-pageFilter__daterangepicker mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="dashboard_period" class="form-label">Период</label>
                <div class="input-group">
                  <input type="text" class="form-control pageFilter__daterange js-daterangepicker" id="dashboard_period" name="dates" autocomplete="off"><span class="input-group-text"><label for="dashboard_period"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
                <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="24.01.2024">
                <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="22.02.2024">
              </div>
              <div class="col-md mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <!-- <label for="shopDashboard" class="form-label">Магазин</label>
                <select v-model="filter.stores" id="shopDashboard" class="form-select" >
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select> -->

                <div class="dropdown">
                  <label class="form-label">Магазин</label>
                  <button class="dropdown-select-button" data-bs-toggle="dropdown">
                    <span v-if="filter.stores.length == 0">Выберите магазины </span v-else><span>{{ filter.stores.join(', ') }}</span>&nbsp;&nbsp;&nbsp;
                  </button>

                  <ul class="dropdown-menu dropdown-select-list">
                    <li v-for="item in stores">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" :value="item.title" v-model="filter.stores" :id="`store_${item.title}`">
                        <label class="form-check-label" :for="`store_${item.title}`">
                          {{ item.title }}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brandDashboard" class="form-label">Бренд</label>
                <select v-model="filter.brands" id="brandDashboard" class="form-select" >
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace" >
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
<option>yandex</option>
                </select>
              </div>
              <div class="col-md mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12 d-flex">
                <button class="btn btn-primary mt-auto" @click="applyFilter">Применить</button>
              </div>
            </div>
          </form>

          <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 2">
            <p>У вас не добавлено ни одного магазина, загрузите свой первый магазин в разделе "<a href="/user-stores">Магазины</a>" </p>
          </div>

          <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 1">
            <p>У вас нет активной подписки, вы можете продлить подписку в разделе "<a href="/tariff">Подписка и платежи</a>" </p>
          </div>

          <div v-if="$store.state.user_info.check == 0">
          <div class="modal-loader d-flex justify-content-center" v-if="loading">
              <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
          </div>
          <div class="content-data dashboardInfo" v-else>
            <!--new data-->

            <div class="w-100 my-5">
              <select class="mb-2" @change="switchCharData()" v-model="charDataOption">
                <option value="1">Отображать по дням</option>
                <option value="2">Отображать по неделям</option>
              </select>
              <line-chart :data="charData" width="100%" :colors="['#805dca', '#e7515a']" legend="bottom" prefix="₽" thousands=" "/>
            </div>

            <div class="row mt-5">
            <div class="col-lg-3 col-12 mb-lg-5 mb-3">
            <div class="dashboard_info_block me-lg-3">
              <div class="d-flex title-info-block">
                <div class="icon-fill-purple icon-block mb-auto">
                  <i class="bi bi-bar-chart"></i>
                </div>
                <div>
                  <div class="title-info-2">
                    <span>Заказы</span>
                  </div>
                  <div class="title-info-1">
                    <span>{{ parseInt(result.items.orders.money).toLocaleString('ru') }}</span> ₽
                    <span :class="{
                        'diff-more': resultDiff(result.items.orders.money, resultPeriod.items.orders.money)[1] == 'plus',
                        'diff-less': resultDiff(result.items.orders.money, resultPeriod.items.orders.money)[1] == 'minus'
                      }">
                      {{ resultDiff(result.items.orders.money, resultPeriod.items.orders.money)[0] }} 
                      <span v-if="resultDiff(result.items.orders.money, resultPeriod.items.orders.money)[1] == 'plus'">↑</span>
                      <span v-if="resultDiff(result.items.orders.money, resultPeriod.items.orders.money)[1] == 'minus'">↓</span>
                    </span>
                  </div>
                  <div class="title-info-2">
                    <span>{{ parseInt(result.items.orders.count).toLocaleString('ru') }}</span> шт.
                    <span :class="{
                      'diff-more': resultDiff(result.items.orders.count, resultPeriod.items.orders.count)[1] == 'plus',
                      'diff-less': resultDiff(result.items.orders.count, resultPeriod.items.orders.count)[1] == 'minus'
                    }">
                      {{ resultDiff(result.items.orders.count, resultPeriod.items.orders.count)[0] }} 
                      <span v-if="resultDiff(result.items.orders.count, resultPeriod.items.orders.count)[1] == 'plus'">↑</span>
                      <span v-if="resultDiff(result.items.orders.count, resultPeriod.items.orders.count)[1] == 'minus'">↓</span>
                    </span>
                  </div>
                  
                </div>
              </div>
            </div>
            </div>

            <div class="col-lg-3 col-12 mb-lg-5 mb-3">
            <div class="dashboard_info_block me-lg-3">
              <div class="d-flex title-info-block">
                <div class="icon-fill-purple icon-block mb-auto">
                  <i class="bi bi-bag-check"></i>
                </div>
                <div>
                  <div class="title-info-2">
                    <span>Продажи</span>
                  </div>
                  <div class="title-info-1">
                    <span>{{ parseInt(result.items.sales.money).toLocaleString('ru') }}</span> ₽
                    <span :class="{
                        'diff-more': resultDiff(result.items.sales.money, resultPeriod.items.sales.money)[1] == 'plus',
                        'diff-less': resultDiff(result.items.sales.money, resultPeriod.items.sales.money)[1] == 'minus'
                      }">
                      {{ resultDiff(result.items.sales.money, resultPeriod.items.sales.money)[0] }} 
                      <span v-if="resultDiff(result.items.sales.money, resultPeriod.items.sales.money)[1] == 'plus'">↑</span>
                      <span v-if="resultDiff(result.items.sales.money, resultPeriod.items.sales.money)[1] == 'minus'">↓</span>
                    </span>
                  </div>
                  <div class="title-info-2">
                    <span>{{ parseInt(result.items.sales.count).toLocaleString('ru') }}</span> шт.
                    <span :class="{
                      'diff-more': resultDiff(result.items.sales.count, resultPeriod.items.sales.count)[1] == 'plus',
                      'diff-less': resultDiff(result.items.sales.count, resultPeriod.items.sales.count)[1] == 'minus'
                    }">
                      {{ resultDiff(result.items.sales.count, resultPeriod.items.sales.count)[0] }} 
                      <span v-if="resultDiff(result.items.sales.count, resultPeriod.items.sales.count)[1] == 'plus'">↑</span>
                      <span v-if="resultDiff(result.items.sales.count, resultPeriod.items.sales.count)[1] == 'minus'">↓</span>
                    </span>
                  </div>
                  
                </div>
              </div>
            </div>
            </div>

            <div class="col-lg-3 col-12 mb-lg-5 mb-3">
            <div class="dashboard_info_block me-lg-3">
              <div class="d-flex title-info-block">
                <div class="icon-fill-purple icon-block mb-auto">
                  <i class="bi bi-wallet2"></i>
                </div>
                <div>
                  <div class="title-info-2">
                    <span>Расходы</span>
                  </div>
                  <div class="title-info-1">
                    <span>{{ parseInt(result.finance.expenses).toLocaleString('ru') }}</span> ₽
                    <span :class="{
                        'diff-more': resultDiff(result.finance.expenses, resultPeriod.finance.expenses)[1] == 'plus',
                        'diff-less': resultDiff(result.finance.expenses, resultPeriod.finance.expenses)[1] == 'minus'
                      }">
                      {{ resultDiff(result.finance.expenses, resultPeriod.finance.expenses)[0] }} 
                      <span v-if="resultDiff(result.finance.expenses, resultPeriod.finance.expenses)[1] == 'plus'">↑</span>
                      <span v-if="resultDiff(result.finance.expenses, resultPeriod.finance.expenses)[1] == 'minus'">↓</span>
                    </span>
                  </div>
                  <div class="title-info-2">
                    <span>{{ parseInt(result.finance.cost_price).toLocaleString('ru') }}</span> ₽
                    <span class="ps-2">себест.</span>
                  </div>
                  
                </div>
              </div>
            </div>
            </div>

            <div class="col-lg-3 col-12 mb-lg-5 mb-3">
            <div class="dashboard_info_block">
              <div class="d-flex title-info-block">
                <div class="icon-fill-purple icon-block mb-auto">
                  <i class="bi bi-cash-stack"></i>
                </div>
                <div>
                  <div class="title-info-2">
                    <span>Прибыль</span>
                  </div>
                  <div class="title-info-1">
                    <span>{{ parseInt(result.finance.profit).toLocaleString('ru') }}</span> ₽
                    <span class="dashboard__diff" :class="{
                      'diff-more': resultDiff(result.finance.profit, resultPeriod.finance.profit)[1] == 'plus',
                      'diff-less': resultDiff(result.finance.profit, resultPeriod.finance.profit)[1] == 'minus'
                    }">
                    {{ resultDiff(result.finance.profit, resultPeriod.finance.profit)[0] }} 
                    <span v-if="resultDiff(result.finance.profit, resultPeriod.finance.profit)[1] == 'plus'">↑</span>
                    <span v-if="resultDiff(result.finance.profit, resultPeriod.finance.profit)[1] == 'minus'">↓</span>
                    </span>
                  </div>
                  <div class="title-info-2">
                    <span>{{ (result.profit.roi).toFixed(2) }}</span> %
                    <span class="ps-2">ROI</span>
                  </div>
                  
                </div>
              </div>
            </div>
            </div>
          
            </div>
            
            <div class="row">

              <div class="col-lg-9 col-12 mb-lg-0 mb-4">
                <h4 class="mb-4">Статистика</h4>
                <div class="row">

                <div class="col-lg-4 col-12 mb-3">
                <div class="dashboard_info_block me-lg-3">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-hand-thumbs-down"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      <span>Возвраты</span>
                    </div>
                    <div class="title-info-1">
                      <span>{{ parseInt(result.items.return.money).toLocaleString('ru') }}</span> ₽
                      <span :class="{
                          'diff-more': resultDiff(result.items.return.money, resultPeriod.items.return.money)[1] == 'plus',
                          'diff-less': resultDiff(result.items.return.money, resultPeriod.items.return.money)[1] == 'minus'
                        }">
                        {{ resultDiff(result.items.return.money, resultPeriod.items.return.money)[0] }} 
                        <span v-if="resultDiff(result.items.return.money, resultPeriod.items.return.money)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.items.return.money, resultPeriod.items.return.money)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                    <div class="title-info-2">
                      <span>{{ parseInt(result.items.return.count).toLocaleString('ru') }}</span> шт.
                      <span :class="{
                          'diff-more': resultDiff(result.items.return.count, resultPeriod.items.return.count)[1] == 'plus',
                          'diff-less': resultDiff(result.items.return.count, resultPeriod.items.return.count)[1] == 'minus'
                        }">
                        {{ resultDiff(result.items.return.count, resultPeriod.items.return.count)[0] }} 
                        <span v-if="resultDiff(result.items.return.count, resultPeriod.items.return.count)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.items.return.count, resultPeriod.items.return.count)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                  </div>
                </div>
                </div>
                </div>

                <div class="col-lg-4 col-12 mb-3">
                  <div class="dashboard_info_block me-lg-3">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-ban"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      <span>Отмены</span>
                    </div>
                    <div class="title-info-1">
                      <span>{{ parseInt(result.items.cancel.money).toLocaleString('ru') }}</span> ₽
                      <span :class="{
                          'diff-more': resultDiff(result.items.cancel.money, resultPeriod.items.cancel.money)[1] == 'plus',
                          'diff-less': resultDiff(result.items.cancel.money, resultPeriod.items.cancel.money)[1] == 'minus'
                        }">
                        {{ resultDiff(result.items.cancel.money, resultPeriod.items.cancel.money)[0] }} 
                        <span v-if="resultDiff(result.items.cancel.money, resultPeriod.items.cancel.money)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.items.cancel.money, resultPeriod.items.cancel.money)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                    <div class="title-info-2">
                      <span>{{ parseInt(result.items.cancel.count).toLocaleString('ru') }}</span> шт.
                      <span :class="{
                          'diff-more': resultDiff(result.items.cancel.count, resultPeriod.items.cancel.count)[1] == 'plus',
                          'diff-less': resultDiff(result.items.return.count, resultPeriod.items.return.count)[1] == 'minus'
                        }">
                        {{ resultDiff(result.items.cancel.count, resultPeriod.items.cancel.count)[0] }} 
                        <span v-if="resultDiff(result.items.cancel.count, resultPeriod.items.cancel.count)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.items.cancel.count, resultPeriod.items.cancel.count)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                  </div>
                </div>
                </div>
                </div>

                <div class="col-lg-4 col-12 mb-3">
                  <div class="dashboard_info_block me-lg-3">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-pie-chart-fill"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      <span>Процент выкупа</span>
                    </div>
                    <div class="title-info-1">
                      <span>{{ (result.items.buyout).toFixed(2) }}</span> %
                      <span :class="{
                          'diff-more': resultDiff(result.items.buyout, resultPeriod.items.buyout)[1] == 'plus',
                          'diff-less': resultDiff(result.items.buyout, resultPeriod.items.buyout)[1] == 'minus'
                        }">
                        {{ resultDiff(result.items.buyout, resultPeriod.items.buyout)[0] }} 
                        <span v-if="resultDiff(result.items.buyout, resultPeriod.items.buyout)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.items.buyout, resultPeriod.items.buyout)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                  </div>
                </div>
                </div>
                </div>

                <div class="col-lg-4 col-12 mb-3">
                  <div class="dashboard_info_block me-lg-3">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-graph-up"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      <span>Среднее заказов в день</span>
                    </div>
                    <div class="title-info-1">
                      <span>{{ parseInt(result.items.average_orders.money).toLocaleString('ru') }}</span> ₽
                      <span :class="{
                          'diff-more': resultDiff(result.items.average_orders.money, resultPeriod.items.average_orders.money)[1] == 'plus',
                          'diff-less': resultDiff(result.items.average_orders.money, resultPeriod.items.average_orders.money)[1] == 'minus'
                        }">
                        {{ resultDiff(result.items.average_orders.money, resultPeriod.items.average_orders.money)[0] }} 
                        <span v-if="resultDiff(result.items.average_orders.money, resultPeriod.items.average_orders.money)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.items.average_orders.money, resultPeriod.items.average_orders.money)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                    <div class="title-info-2">
                      <span>{{ parseInt(result.items.average_orders.count).toLocaleString('ru') }}</span> шт.
                      <span :class="{
                          'diff-more': resultDiff(result.items.average_orders.count, resultPeriod.items.average_orders.count)[1] == 'plus',
                          'diff-less': resultDiff(result.items.average_orders.count, resultPeriod.items.average_orders.count)[1] == 'minus'
                        }">
                        {{ resultDiff(result.items.average_orders.count, resultPeriod.items.average_orders.count)[0] }} 
                        <span v-if="resultDiff(result.items.average_orders.count, resultPeriod.items.average_orders.count)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.items.average_orders.count, resultPeriod.items.average_orders.count)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                  </div>
                </div>
                </div>
                </div>

                <div class="col-lg-4 col-12 mb-3">
                  <div class="dashboard_info_block me-lg-3">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-wallet2"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      <span>Среднее продаж в день</span>
                    </div>
                    <div class="title-info-1">
                      <span>{{ parseInt(result.items.average_sales.money).toLocaleString('ru') }}</span> ₽
                      <span :class="{
                          'diff-more': resultDiff(result.items.average_sales.money, resultPeriod.items.average_sales.money)[1] == 'plus',
                          'diff-less': resultDiff(result.items.average_sales.money, resultPeriod.items.average_sales.money)[1] == 'minus'
                        }">
                        {{ resultDiff(result.items.average_sales.money, resultPeriod.items.average_sales.money)[0] }} 
                        <span v-if="resultDiff(result.items.average_sales.money, resultPeriod.items.average_sales.money)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.items.average_sales.money, resultPeriod.items.average_sales.money)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                    <div class="title-info-2">
                      <span>{{ result.items.average_sales.count }}</span> шт.
                      <span :class="{
                          'diff-more': resultDiff(result.items.average_sales.count, resultPeriod.items.average_sales.count)[1] == 'plus',
                          'diff-less': resultDiff(result.items.average_sales.count, resultPeriod.items.average_sales.count)[1] == 'minus'
                        }">
                        {{ resultDiff(result.items.average_sales.count, resultPeriod.items.average_sales.count)[0] }} 
                        <span v-if="resultDiff(result.items.average_sales.count, resultPeriod.items.average_sales.count)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.items.average_sales.count, resultPeriod.items.average_sales.count)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                  </div>
                </div>
                </div>
                </div>

                <div class="col-lg-4 col-12 mb-3">
                  <div class="dashboard_info_block me-lg-3">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-receipt-cutoff"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      <span>Средний чек</span>
                    </div>
                    <div class="title-info-1">
                      <span>{{ parseInt(result.items.average_bill).toLocaleString('ru') }}</span> ₽
                      <span :class="{
                          'diff-more': resultDiff(result.items.average_bill, resultPeriod.items.average_bill)[1] == 'plus',
                          'diff-less': resultDiff(result.items.average_bill, resultPeriod.items.average_bill)[1] == 'minus'
                        }">
                        {{ resultDiff(result.items.average_bill, resultPeriod.items.average_bill)[0] }} 
                        <span v-if="resultDiff(result.items.average_bill, resultPeriod.items.average_bill)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.items.average_bill, resultPeriod.items.average_bill)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                  </div>
                </div>
                </div>
                </div>

                </div>

                <div class="w-100 mt-3">
              <div class="dashboard_info_block me-3">
                <h4 class="mb-4">Склад</h4>
                <div class="d-lg-flex justify-content-around">
                  <div class="d-flex title-info-block mb-4 mb-lg-3">
                    <div class="icon-fill-purple icon-block mb-auto">
                      <i class="bi bi-key"></i>
                    </div>
                    <div>
                      <div class="title-info-2">
                        <span>Остатки FBO, шт</span>
                      </div>
                      <div class="title-info-1">
                        <span>{{ parseInt(result.stocks.rest).toLocaleString('ru') }}</span> шт.
                      </div>
                    </div>
                  </div>

                  <div class="d-flex title-info-block mb-4 mb-lg-3">
                    <div class="icon-fill-purple icon-block mb-auto">
                      <i class="bi bi-calculator"></i>
                    </div>
                    <div>
                      <div class="title-info-2">
                        <span>Капитал. FBO (себестоим.)</span>
                      </div>
                      <div class="title-info-1">
                        <span>{{ parseInt(result.stocks.fbo1).toLocaleString('ru') }}</span> ₽
                      </div>
                    </div>
                  </div>

                  <div class="d-flex title-info-block mb-4 mb-lg-3">
                    <div class="icon-fill-purple icon-block mb-auto">
                      <i class="bi bi-piggy-bank"></i>
                    </div>
                    <div>
                      <div class="title-info-2">
                        <span>Капитал. FBO (розница)</span>
                      </div>
                      <div class="title-info-1">
                        <span>{{ parseInt(result.stocks.fbo2).toLocaleString('ru') }}</span> ₽
                      </div>
                    </div>
                  </div>

                </div>
                <hr class="dropdown-divider">

                <div class="table table-dashboard">
                <table class="custom-table">
                        <thead>
                          <tr>
                            <td></td>
                            <td>Артикул</td>
                            <td>Остаток</td>
                            <td>Закончится к</td>
                            <td>Магазин</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="table-danger" v-for="item in result.deficit">
                            <td><img :src="item.img" alt="" style="width: 30px;"></td>
                            <td>{{ item.title }}</td>
                            <td>{{ item.quantity }}</td>
                            <td v-if="item.days_left > 0">
                              {{ item.date_left }}
                              <br>ост. дней: {{ item.days_left }}
                            </td>
                            <td v-else><br>ост. дней: {{ item.days_left }}</td>
                            <td>{{ item.store }}</td>
                          </tr>
                        </tbody>
                </table>
                </div>
              </div>
              </div>

                </div>


              <div class="col-lg-3 col-12">
                <h4 class="mb-4">Расходы</h4>
              <div class="row">

                <div class="col-12 mb-3">
                <div class="dashboard_info_block">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-badge-ad"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      <span>Реклама</span>
                    </div>
                    <div class="title-info-1">
                      <span>{{ parseInt(result.expenses.adv).toLocaleString('ru') }}</span> ₽
                      <span :class="{
                          'diff-more': resultDiff(result.expenses.adv, resultPeriod.expenses.adv)[1] == 'plus',
                          'diff-less': resultDiff(result.expenses.adv, resultPeriod.expenses.adv)[1] == 'minus'
                        }">
                        {{ resultDiff(result.expenses.adv, resultPeriod.expenses.adv)[0] }} 
                        <span v-if="resultDiff(result.expenses.adv, resultPeriod.expenses.adv)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.expenses.adv, resultPeriod.expenses.adv)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                    <div class="title-info-2">
                      <span>{{ parseInt(result.expenses.adv_balance).toLocaleString('ru') }}</span> ₽
                      <span class="ps-2">С баланса</span>
                    </div>
                    <div class="title-info-2">
                      <span>{{ parseInt(result.expenses.adv_bank).toLocaleString('ru') }}</span> ₽
                      <span class="ps-2">Со счета</span>
                    </div>
                    <div class="title-info-2">
                      <span>{{ parseInt(result.expenses.adv_bonus).toLocaleString('ru') }}</span> ₽
                      <span class="ps-2">Бонусами</span>
                    </div>
                  </div>
                </div>
                </div>
                </div>

                <div class="col-12 mb-3">
                <div class="dashboard_info_block">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-shop"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      <span>Комиссия</span>
                    </div>
                    <div class="title-info-1">
                      <span>{{ parseInt(result.expenses.commission).toLocaleString('ru') }}</span> ₽
                      <span :class="{
                          'diff-more': resultDiff(result.expenses.commission, resultPeriod.expenses.commission)[1] == 'plus',
                          'diff-less': resultDiff(result.expenses.commission, resultPeriod.expenses.commission)[1] == 'minus'
                        }">
                        {{ resultDiff(result.expenses.commission, resultPeriod.expenses.commission)[0] }} 
                        <span v-if="resultDiff(result.expenses.commission, resultPeriod.expenses.commission)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.expenses.commission, resultPeriod.expenses.commission)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                  </div>
                </div>
                </div>
                </div>

                <div class="col-12 mb-3">
                <div class="dashboard_info_block">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-truck"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      <span>Логистика</span>
                    </div>
                    <div class="title-info-1">
                      <span>{{ parseInt(result.expenses.logistics).toLocaleString('ru') }}</span> ₽
                      <span :class="{
                          'diff-more': resultDiff(result.expenses.logistics, resultPeriod.expenses.logistics)[1] == 'plus',
                          'diff-less': resultDiff(result.expenses.logistics, resultPeriod.expenses.logistics)[1] == 'minus'
                        }">
                        {{ resultDiff(result.expenses.logistics, resultPeriod.expenses.logistics)[0] }} 
                        <span v-if="resultDiff(result.expenses.logistics, resultPeriod.expenses.logistics)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.expenses.logistics, resultPeriod.expenses.logistics)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                  </div>
                </div>
                </div>
                </div>

                <div class="col-12 mb-3">
                <div class="dashboard_info_block">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-house-lock"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      <span>Хранение</span>
                    </div>
                    <div class="title-info-1">
                      <span>{{ parseInt(result.expenses.storage).toLocaleString('ru') }}</span> ₽
                      <span :class="{
                          'diff-more': resultDiff(result.expenses.storage, resultPeriod.expenses.storage)[1] == 'plus',
                          'diff-less': resultDiff(result.expenses.storage, resultPeriod.expenses.storage)[1] == 'minus'
                        }">
                        {{ resultDiff(result.expenses.storage, resultPeriod.expenses.storage)[0] }} 
                        <span v-if="resultDiff(result.expenses.storage, resultPeriod.expenses.storage)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.expenses.storage, resultPeriod.expenses.storage)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                  </div>
                </div>
                </div>
                </div>

              </div>
            
              
              <div class="mt-3">
              <div class="w-100">
                <h4 class="mb-4">Финансы</h4>

                <div class="row">

                <div class="col-12 mb-3">
                <div class="dashboard_info_block">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-rocket-takeoff"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      <span>Выручка</span>
                    </div>
                    <div class="title-info-1">
                      <span>{{ parseInt(result.finance.gain).toLocaleString('ru') }}</span> ₽
                      <span :class="{
                          'diff-more': resultDiff(result.finance.gain, resultPeriod.finance.gain)[1] == 'plus',
                          'diff-less': resultDiff(result.finance.gain, resultPeriod.finance.gain)[1] == 'minus'
                        }">
                        {{ resultDiff(result.finance.gain, resultPeriod.finance.gain)[0] }} 
                        <span v-if="resultDiff(result.finance.gain, resultPeriod.finance.gain)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.finance.gain, resultPeriod.finance.gain)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                  </div>
                </div>
                </div>
                </div>

                <div class="col-12 mb-3">
                <div class="dashboard_info_block">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-currency-exchange"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      <span>Маржинальная прибыль</span>
                    </div>
                    <div class="title-info-1">
                      <span>{{ parseInt(result.finance.marginality).toLocaleString('ru') }}</span> ₽
                      <span :class="{
                          'diff-more': resultDiff(result.finance.marginality, resultPeriod.finance.marginality)[1] == 'plus',
                          'diff-less': resultDiff(result.finance.marginality, resultPeriod.finance.marginality)[1] == 'minus'
                        }">
                        {{ resultDiff(result.finance.marginality, resultPeriod.finance.marginality)[0] }} 
                        <span v-if="resultDiff(result.finance.marginality, resultPeriod.finance.marginality)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.finance.marginality, resultPeriod.finance.marginality)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                  </div>
                </div>
                </div>
                </div>

                <div class="col-12 mb-3">
                <div class="dashboard_info_block">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-pen"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      <span>Операционная прибыль</span>
                    </div>
                    <div class="title-info-1">
                      <span>{{ parseInt(result.finance.oper).toLocaleString('ru') }}</span> ₽
                      <span :class="{
                          'diff-more': resultDiff(result.finance.oper, resultPeriod.finance.oper)[1] == 'plus',
                          'diff-less': resultDiff(result.finance.oper, resultPeriod.finance.oper)[1] == 'minus'
                        }">
                        {{ resultDiff(result.finance.oper, resultPeriod.finance.oper)[0] }} 
                        <span v-if="resultDiff(result.finance.oper, resultPeriod.finance.oper)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.finance.oper, resultPeriod.finance.oper)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                  </div>
                </div>
                </div>
                </div>

                <!-- <div class="col-12 mb-3">
                <div class="dashboard_info_block">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-bank"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      <span>Налог</span>
                    </div>
                    <div class="title-info-1">
                      <span>{{ result.finance.tax }}</span> ₽
                    </div>
                  </div>
                </div>
                </div>
                </div> -->

                <div class="col-12 mb-3">
                <div class="dashboard_info_block">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-clipboard-data"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      Средняя прибыль
                    </div>
                    <div class="title-info-1">
                      <span>{{ parseInt(result.finance.average_profit).toLocaleString('ru') }}</span> ₽
                      <span :class="{
                          'diff-more': resultDiff(result.finance.average_profit, resultPeriod.finance.average_profit)[1] == 'plus',
                          'diff-less': resultDiff(result.finance.average_profit, resultPeriod.finance.average_profit)[1] == 'minus'
                        }">
                        {{ resultDiff(result.finance.average_profit, resultPeriod.finance.average_profit)[0] }} 
                        <span v-if="resultDiff(result.finance.average_profit, resultPeriod.finance.average_profit)[1] == 'plus'">↑</span>
                        <span v-if="resultDiff(result.finance.average_profit, resultPeriod.finance.average_profit)[1] == 'minus'">↓</span>
                      </span>
                    </div>
                  </div>
                </div>
                </div>
                </div>

                <div class="col-12 mb-3">
                <div class="dashboard_info_block">
                <div class="d-flex title-info-block">
                  <div class="icon-fill-purple icon-block mb-auto">
                    <i class="bi bi-percent"></i>
                  </div>
                  <div>
                    <div class="title-info-2">
                      Рентабельность ОП
                    </div>
                    <div class="title-info-1">
                      <span>{{ (result.profit.vp).toFixed(2) }}</span> %
                    </div>
                  </div>
                </div>
                </div>
                </div>

                </div>
              </div>
              </div>
              </div>


            </div>
            <!--old data-->
            
        </div>
        </div>
      </div>
    </div>
  
</template>

<script>
import axios from 'axios';
export default{
  data(){
    return{
      charData: [],
      stores: [],
      brands: [],
      filter: {
        stores: [],
        brands: '',
        marketplace: ''
      },
      loading: true,
      charDataOption: 1
    }
  },
  methods: {
    updateInfo(start, end){
      this.loading = true
      this.updatePeriodInfo(this.getDayPeriod(new Date(start), -this.dateDiff(new Date(start), new Date(end))), this.getDayPeriod(new Date(start), -1))
      let post_data = {
        start_date: start,
        end_date: end,
        store: this.filter.stores,
        brand: this.filter.brands,
        marketplace: this.filter.marketplace,
        user_id: this.$store.state.user_info.id
      }
      axios.post('https://tapwill.ru/api/get_data.php', post_data).then((response)=>{
        console.log(response.data)
        this.$store.commit('insertDashboardData', response.data)
        this.charData = [
          {name: 'Заказы', data: this.$store.state.dashboard_data['graph']['orders']},
          {name: 'Продажи', data: this.$store.state.dashboard_data['graph']['sales']},
        ]
        console.log(this.groupDataByWeek(this.charData[0].data))
        this.loading = false;
      });
    },
    updatePeriodInfo(start, end){
      let post_data = {
        start_date: start,
        end_date: end,
        store: this.filter.stores,
        brand: this.filter.brands,
        marketplace: this.filter.marketplace,
        user_id: this.$store.state.user_info.id
      }
      axios.post('https://tapwill.ru/api/get_data.php', post_data).then((response)=>{
        console.log(response.data)
        this.$store.commit('insertDashboardPeriodData', response.data)
      });
    },
    applyFilter(){
      this.updateInfo($('.js-daterangepicker').data('daterangepicker').startDate.format('YYYY-MM-DD'),  $('.js-daterangepicker').data('daterangepicker').endDate.format('YYYY-MM-DD'))
    },
    dateDiff(firstDate, secondDate) {        
      return Math.round((secondDate - firstDate) / (1000 * 60 * 60 * 24))+1;
    },
    getDayPeriod(t, n) {
      let time = t.getTime();
      let changedDate = new Date(time + (n * 24 * 60 * 60 * 1000));
      // t.setTime(changedDate.getTime());
      return changedDate.toISOString().split('T')[0];
    },
    resultDiff(a, b) {
      let diff = ''
      let diffSign = ''
      if(b!==0 && a!== 0){
        diff = parseInt(100 * ((b-a)/a))

        diffSign = 'minus'

        if(diff<0){
          diff = diff + '%'
          diff = diff.slice(1)
          diffSign = 'plus'
        }
        else if(diff>0){
          diff = diff + '%'
        }
        else{
          diff = diff + '%'
        }
      }

      return [diff, diffSign]
    },

    groupDataByWeek(data) {
      const weeklyData = {};
      const entries = Object.entries(data);  // Преобразуем объект в массив [дата, значение]

      // Сортируем массив по датам
      entries.sort((a, b) => new Date(a[0]) - new Date(b[0]));

      for (let i = 0; i < entries.length; i++) {
        const [date, value] = entries[i];
        const currentDate = new Date(date);
        const weekStart = new Date(currentDate);
        weekStart.setDate(currentDate.getDate() - currentDate.getDay() + 1);  // Понедельник

        const weekStartKey = weekStart.toISOString().split('T')[0];  // Преобразуем в строку формата YYYY-MM-DD

        // Если такой ключ уже существует, суммируем значения
        if (weeklyData[weekStartKey]) {
          weeklyData[weekStartKey] += Number(value);
        } else {
          weeklyData[weekStartKey] = Number(value);  // Инициализируем новый ключ
        }
      }

      return weeklyData;
    },

    switchCharData(){
      if(this.charDataOption == 1) this.charData = [
        {name: 'Заказы', data: this.$store.state.dashboard_data['graph']['orders']},
        {name: 'Продажи', data: this.$store.state.dashboard_data['graph']['sales']}
      ]
      else{
        this.charData = [
        {name: 'Заказы', data: this.groupDataByWeek(this.$store.state.dashboard_data['graph']['orders'])},
        {name: 'Продажи', data: this.groupDataByWeek(this.$store.state.dashboard_data['graph']['sales'])}
      ]
      }

      console.log(this.charData)
    }

  },
  computed: {
    result(){
        return this.$store.state.dashboard_data;
    },
    resultPeriod(){
      return this.$store.state.dashboardPeriod_data
    }
},
  created(){
    this.updateInfo(moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
    axios.post('https://tapwill.ru/api/get_store.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
      this.stores = response.data
      console.log(this.stores)
    })
    axios.post('https://tapwill.ru/api/get_brands.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
      this.brands = response.data
      console.log(this.brands)
    })
  },
  mounted(){

    let start = moment().subtract(30, 'days');
    let end = moment();

    function cb(start, end) {
        $('.js-daterangepicker span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    }

    $('.js-daterangepicker').daterangepicker({
      "locale": {
        "format": "DD.MM.YYYY",
        "separator": " - ",
        "applyLabel": "Сохранить",
        "cancelLabel": "Назад",
        "daysOfWeek": [
            "Вс",
            "Пн",
            "Вт",
            "Ср",
            "Чт",
            "Пт",
            "Сб"
        ],
        "monthNames": [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"
        ],
        "firstDay": 1
    },
      
      opens: 'left',
      autoUpdateInput: true,
      startDate: start,
      endDate: end,
      ranges: {
           'Сегодня': [moment(), moment()],
           'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
           'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
           'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
           'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
           'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
      }, (start, end) => {
        $('.js-daterangepicker').data('daterangepicker').startDate = start
        $('.js-daterangepicker').data('daterangepicker').endDate = end
        this.updateInfo(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
      }, cb);


    cb(start, end);

    $('li[data-range-key="Custom Range"]').text('Произвольно')

  }
}
</script>

<style lang="scss">
@import '../assets/style/dashboard.scss';
</style>