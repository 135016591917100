<template>
<div class="modal fade" id="mobile_modal_info" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="mobile_modal_info">Подробная информация</h5>
        <button type="button" data-bs-dismiss="modal" class="btn-close" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="table">
          <table class="custom-table">
            <tbody v-if="items.length !== 0" class="modal-info-table">
              <tr>
                <td><img :src="items[itemId].img" alt=""></td>
              </tr>
              <tr>
                <td>Артикул продавца</td>
                <td>{{ items[itemId].supplierArticle }}</td>
              </tr>
              <tr>
                <td>Артикул {{ items[itemId].marketplace }}</td>
                <td>{{ items[itemId].nmId }}</td>
              </tr>
              <tr>
                <td>ABC по выручке</td>
                <td>{{ items[itemId].gain_abc }}</td>
              </tr>
              <tr>
                <td>ABC по прибыли</td>
                <td>{{ items[itemId].profit_abc }}</td>
              </tr>
              <tr>
                <td>Продажи, шт</td>
                <td>{{ items[itemId].sales_count }}</td>
              </tr>
              <tr>
                <td>Продажи, руб.</td>
                <td>{{ items[itemId].sales_sum }}</td>
              </tr>
              <tr>
                <td>Выручка, руб.</td>
                <td>{{ items[itemId].gain }}</td>
              </tr>
              <tr>
                <td>Себестоимость, руб.</td>
                <td>{{ items[itemId].cost_price }}</td>
              </tr>
              <tr>
                <td>Прибыль, руб.</td>
                <td>{{ items[itemId].profit }}</td>
              </tr>
              <tr>
                <td>Возвраты, руб.</td>
                <td>{{ items[itemId].return }}</td>
              </tr>
              <tr>
                <td>Комиссия, руб.</td>
                <td>{{ items[itemId].commission }}</td>
              </tr>
              <tr>
                <td>Хранение, руб.</td>
                <td>{{ items[itemId].storage }}</td>
              </tr>
              <tr>
                <td>Логистика, руб.</td>
                <td>{{ items[itemId].logistics }}</td>
              </tr>
              <tr>
                <td>Реклама, руб.</td>
                <td>{{ items[itemId].adv }}</td>
              </tr>
              <tr>
                <td>Прочие расходы, руб.</td>
                <td>{{ Number(items[itemId].penalty) + Number(items[itemId].acquiring) }}</td>
              </tr>
              <tr>
                <td>Логистика МП, руб.</td>
                <td>{{ items[itemId].stocks }}</td>
              </tr>
              <tr>
                <td>На складе, шт</td>
                <td>{{ items[itemId].acquiring }}</td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

    <div class="container-fluid">

      <div class="alert alert-warning" role="alert">
<p class="mb-0" style="font-size: 0.9em;" v-if="$store.state.user_info.check == 2">Добавьте API ключ чтобы получить 14 дней бесплатного теста сервиса и аудит магазина от рекомендованного эксперта Tapwill - Laba Market!</p>
        <p class="mb-0" style="font-size: 0.9em;" v-else>Нашли критическую ошибку в работе сервиса? Напишите на почту <a href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот <a href="https://t.me/tapwill_support_bot">@tapwill_support_bot</a>. Мы подарим вам дополнительно 5 дней подписки. </p>
            </div>
      
  <div class="head-block row row-cols-1 row-cols-lg-2 justify-content-between">
    <div class="col content-title">
      <h1>ABC анализ по товарам</h1></div>
  </div>
  <div class="content-block">
    <div class="accordion d-lg-none" id="filterOpen">
            <div class="accordion-item border-0">
                <h2 class="accordion-header">
                <button class="accordion-button collapsed ps-0" style="width: fit-content;" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-controls="collapseOne">
                   <span class="pe-3">Показать параметры</span>
                 </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#filterOpen">
                <div class="accordion-body">

                  <div class="pageFilter">
      <div class="js-pageFilter__form">
        <div class="row row-cols-lg-auto align-items-end">
          <div class="col-md js-pageFilter__daterangepicker mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
            <label  class="form-label">Период</label>
            <div class="input-group">
              <input type="text" class="form-control pageFilter__daterange js-daterangepicker" id="dashboard_period_mob" autocomplete="off"><span class="input-group-text"><label for="dashboard_period_mob"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
            <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="10.02.2024">
            <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="10.03.2024">
          </div>
          <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brand_8c4d3a94" class="form-label">Бренд</label>
                <select class="form-select" v-model="filter.brand" >
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Магазин</label>
                <select class="form-select" v-model="filter.store" >
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace" >
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
<option>yandex</option>
                </select>
              </div>
          <div class="col-md mb-3">
            <div class="btn-toolbar">
              <div class="btn-group me-2">
                <button class="btn btn-primary" @click="applyFilter">Применить</button>
              </div>
              <div class="btn-group"><a href="/abc" class="btn btn-outline-secondary">Сбросить</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>

                  </div>
                  </div>
                  </div>
                  </div>

    <div class="pageFilter d-lg-block d-none">
      <div class="js-pageFilter__form">
        <div class="row row-cols-lg-auto align-items-end">
          <div class="col-md js-pageFilter__daterangepicker mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
            <label  class="form-label">Период</label>
            <div class="input-group">
              <input type="text" class="form-control pageFilter__daterange js-daterangepicker" id="dashboard_period" autocomplete="off"><span class="input-group-text"><label for="dashboard_period"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"><path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"></path><path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path></svg></label></span></div>
            <input type="hidden" name="filter[start_period]" class="js-daterangepicker__start" value="10.02.2024">
            <input type="hidden" name="filter[end_period]" class="js-daterangepicker__end" value="10.03.2024">
          </div>
          <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="brand_8c4d3a94" class="form-label">Бренд</label>
                <select class="form-select" v-model="filter.brand" >
                  <option value="">Не выбрано</option>
                  <option v-for="item in brands">{{ item }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Магазин</label>
                <select class="form-select" v-model="filter.store" >
                  <option value="">Не выбрано</option>
                  <option v-for="item in stores">{{ item.title }}</option>
                </select>
              </div>
              <div class="col-md js-pageFilter__select mb-3 col-xl-auto col-lg-auto col-md-4 col-sm-12">
                <label for="shop_fb54f3c5" class="form-label">Маркетплейс</label>
                <select class="form-select" v-model="filter.marketplace" >
                  <option value="">Не выбрано</option>
                  <option>wildberries</option>
                  <option>ozon</option>
<option>yandex</option>
                </select>
              </div>
          <div class="col-md mb-3">
            <div class="btn-toolbar">
              <div class="btn-group me-2">
                <button class="btn btn-primary" @click="applyFilter">Применить</button>
              </div>
              <div class="btn-group"><a href="/abc" class="btn btn-outline-secondary">Сбросить</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 2">
            <p>У вас не добавлено ни одного магазина, загрузите свой первый магазин в разделе "<a href="/user-stores">Магазины</a>" </p>
          </div>

          <div class="alert alert-danger px-4 mx-auto mt-5" style="width: fit-content;" role="alert" v-if="$store.state.user_info.check == 1">
            <p>У вас нет активной подписки, вы можете продлить подписку в разделе "<a href="/tariff">Подписка и платежи</a>" </p>
          </div>

  <div v-if="$store.state.user_info.check == 0">
    <div class="progress my-5" v-if="loading">
      <div class="progress-bar" role="progressbar" :style="{'width': loadingProgress + '%'}" :aria-valuenow="loadingProgress" aria-valuemin="0" aria-valuemax="100">{{ loadingProgress }}%</div>
    </div>
  <!-- <div class="modal-loader d-flex justify-content-center" v-if="loading">
    <div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>
  </div> -->
  <div class="content-block" v-else>
    <button class="btn btn-primary my-3"><json-excel
            :data="items"
            type="xlsx"
            name="abc.xls"
            :export-fields = exportFields
          >
            <span>Экспорт в Excel</span>
          </json-excel></button>
    <div class="table">
      
      <table class="custom-table d-sm-block d-none">
        <thead>
          <tr>
            <td rowspan="4">Артикул</td>
            <td rowspan="1" colspan="2">ABC анализ</td>
            <td rowspan="2" style="cursor: pointer;"
             @click="sort.order=='DESC' && sort.type=='sales_count' ? sort.order='ASC' : sort.order='DESC'; sort.type='sales_count'; sortItems()">
              Продажи, шт.
              <span v-if="sort.type == 'sales_count' && sort.order == 'DESC'">	&darr;</span>
              <span v-if="sort.type == 'sales_count' && sort.order == 'ASC'">	&uarr;</span>
            </td>
            <td rowspan="2" style="cursor: pointer;"
             @click="sort.order=='DESC' && sort.type=='sales_sum' ? sort.order='ASC' : sort.order='DESC'; sort.type='sales_sum'; sortItems()">
             Продажи, руб.
              <span v-if="sort.type == 'sales_sum' && sort.order == 'DESC'">	&darr;</span>
              <span v-if="sort.type == 'sales_sum' && sort.order == 'ASC'">	&uarr;</span>
            </td>
            <td rowspan="2" style="cursor: pointer;"
              @click="sort.order=='DESC' && sort.type=='gain' ? sort.order='ASC' : sort.order='DESC'; sort.type='gain'; sortItems()">
              Выручка, руб.
              <span v-if="sort.type == 'gain' && sort.order == 'DESC'">	&darr;</span>
              <span v-if="sort.type == 'gain' && sort.order == 'ASC'">	&uarr;</span>
            </td>
            <td rowspan="2" style="cursor: pointer;"
              @click="sort.order=='DESC' && sort.type=='cost_price' ? sort.order='ASC' : sort.order='DESC'; sort.type='cost_price'; sortItems()">
              Себестоимость, руб.
              <span v-if="sort.type == 'cost_price' && sort.order == 'DESC'">	&darr;</span>
              <span v-if="sort.type == 'cost_price' && sort.order == 'ASC'">	&uarr;</span>
            </td>
            <td rowspan="2" style="cursor: pointer;"
              @click="sort.order=='DESC' && sort.type=='profit' ? sort.order='ASC' : sort.order='DESC'; sort.type='profit'; sortItems()">
              Прибыль, руб.
              <span v-if="sort.type == 'profit' && sort.order == 'DESC'">	&darr;</span>
              <span v-if="sort.type == 'profit' && sort.order == 'ASC'">	&uarr;</span>
            </td>
            <td rowspan="2" style="cursor: pointer;"
            @click="sort.order=='DESC' && sort.type=='return' ? sort.order='ASC' : sort.order='DESC'; sort.type='return'; sortItems()">
            Возвраты, руб.
              <span v-if="sort.type == 'return' && sort.order == 'DESC'">	&darr;</span>
              <span v-if="sort.type == 'return' && sort.order == 'ASC'">	&uarr;</span>
            </td>
            <td rowspan="2" style="cursor: pointer;"
              @click="sort.order=='DESC' && sort.type=='commission' ? sort.order='ASC' : sort.order='DESC'; sort.type='commission'; sortItems()">
              Комиссия, руб.
              <span v-if="sort.type == 'commission' && sort.order == 'DESC'">	&darr;</span>
              <span v-if="sort.type == 'commission' && sort.order == 'ASC'">	&uarr;</span>
            </td>
            <td rowspan="2" style="cursor: pointer;"
              @click="sort.order=='DESC' && sort.type=='storage' ? sort.order='ASC' : sort.order='DESC'; sort.type='storage'; sortItems()">
              Хранение, руб.
              <span v-if="sort.type == 'storage' && sort.order == 'DESC'">	&darr;</span>
              <span v-if="sort.type == 'storage' && sort.order == 'ASC'">	&uarr;</span>
            </td>
            <td rowspan="2" style="cursor: pointer;"
              @click="sort.order=='DESC' && sort.type=='logistics' ? sort.order='ASC' : sort.order='DESC'; sort.type='logistics'; sortItems()">
              Логистика, руб.
              <span v-if="sort.type == 'logistics' && sort.order == 'DESC'">	&darr;</span>
              <span v-if="sort.type == 'logistics' && sort.order == 'ASC'">	&uarr;</span>
            </td>
            <td rowspan="2" style="cursor: pointer;"
              @click="sort.order=='DESC' && sort.type=='adv' ? sort.order='ASC' : sort.order='DESC'; sort.type='adv'; sortItems()">
              Реклама, руб.
              <span v-if="sort.type == 'adv' && sort.order == 'DESC'">	&darr;</span>
              <span v-if="sort.type == 'adv' && sort.order == 'ASC'">	&uarr;</span>
            </td>
            <td rowspan="2" style="cursor: pointer;"
              @click="sort.order=='DESC' && sort.type=='acquiring' ? sort.order='ASC' : sort.order='DESC'; sort.type='acquiring'; sortItems()">
              Эквайринг, руб.
              <span v-if="sort.type == 'acquiring' && sort.order == 'DESC'">	&darr;</span>
              <span v-if="sort.type == 'acquiring' && sort.order == 'ASC'">	&uarr;</span>
            </td>
            <td rowspan="2" style="cursor: pointer;"
              @click="sort.order=='DESC' && sort.type=='stocks' ? sort.order='ASC' : sort.order='DESC'; sort.type='stocks'; sortItems()">
              На складе, шт.
              <span v-if="sort.type == 'stocks' && sort.order == 'DESC'">	&darr;</span>
              <span v-if="sort.type == 'stocks' && sort.order == 'ASC'">	&uarr;</span>
            </td>
          </tr>
          <tr>
            <td rowspan="2" style="border-radius: 0;">По выручке</td>
            <td rowspan="2" style="border-radius: 0;">По прибыли</td>
          </tr>
        </thead>
        <tbody>

          <tr v-for="item in items" :class="{'table-success': item.profit_abc=='A', 'table-warning': item.profit_abc=='B', 'table-danger': item.profit_abc=='C'}">
            <td class="productInfo text-left">
              <table>
                <tbody>
                  <tr>
                    <td><img :src="item.img" alt=""></td>
                    <td>{{ item.supplierArticle }}
                      <br><span>{{ item.marketplace }}: {{ item.nmId }}</span></td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>{{ item.gain_abc }}</td>
            <td>{{ item.profit_abc }}</td>
            <td>{{ item.sales_count }}</td>
            <td>{{ item.sales_sum }}</td>
            <td>{{ item.gain }}</td>
            <td>{{ item.cost_price }}</td>
            <td>{{ item.profit }}</td>
            <td>{{ item.return }}</td>
            <td>{{ item.commission }}</td>
            <td>{{ item.storage }}</td>
            <td>{{ item.logistics }}</td>
            <td>{{ item.adv }}</td>
            <td>{{ item.acquiring }}</td>
            <td>{{ item.stocks }}</td>
          </tr>
        
        </tbody>
      </table>

          <table class="custom-table d-sm-none">
            <thead>
              <tr>
                <td rowspan="2" colspan="2">Товар</td>
                <td>ABC <br> (выручка/прибыль)</td>
                <td></td>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in items" :class="{'table-success': item.profit_abc=='A', 'table-warning': item.profit_abc=='B', 'table-danger': item.profit_abc=='C'}">
                <td><img :src="item.img" alt=""></td>
                    <td><span>{{ item.supplierArticle }}</span>
                      <br><span>{{ item.marketplace }}: {{ item.nmId }}</span></td>
                <td>{{ item.gain_abc }} / {{ item.profit_abc }}</td>
                <!--<td>{{ item.stock }}</td>-->
                <td><a class="show_mobile_modal_icon" data-bs-toggle="modal" data-bs-target="#mobile_modal_info" @click="itemId = index"><i class="bi bi-eye"></i></a></td>
              </tr>
            </tbody>
          </table>
    </div>
    </div>
  </div>
</div>
</template>

<style lang="scss">
// @import '@/assets/style/items.scss';

.productInfo td{
    border-width: 0 !important;
}
</style>

<script>
import axios from 'axios'
import JsonExcel from "vue-json-excel3"

export default{
  components: {
    JsonExcel
  },
  data(){
    return {
      items: [],
      filter: {
        store: '',
        brand: '',
        marketplace: ''
      },
      stores: [],
      brands: [],
      loading: true,
      itemId: 0,
      sort: {
        type: '',
        order: 'DESC'
      },
      loadingProgress: 0,
      exportFields: {
        "Артикул продавца": 'supplierArticle',
        "Маркетплейс": 'marketplace',
        "Артикул": 'nmId',
        "ABC по выручке": "gain_abc",
        "ABC по прибыли": "profit_abc",
        "Продажи, шт": "sales_count",
        "Продажи, руб.": "sales_sum",
        "Выручка, руб.": "gain",
        "Себестоимость, руб.": "cost_price",
        "Прибыль, руб.": "profit",
        "Возвраты, руб.": "return",
        "Комиссия, руб.": "commission",
        "Хранение, руб.": "storage",
        "Логистика, руб.": "logistics",
        "Реклама, руб.": "adv",
        "Штрафы, руб.": "penalty",
        "Эквайринг, руб.": "acquiring",
        "На складе, шт": "stocks"
      }
    }
  },
  methods: {
    loadData(start, end){
      this.loadingProgress = 0;
      this.loading = true
      let intervalTime = Number(this.$store.state.user_info.abc_time)*10
      let intervalLoading = setInterval(()=>{
        this.loadingProgress += 1
        if(this.loadingProgress >= 98){
          clearInterval(intervalLoading)
          this.loadingProgress = 98
        }
      }, intervalTime)
      let post_data = {
        start_date: start,
        end_date: end,
        store: this.filter.store,
        brand: this.filter.brand,
        marketplace: this.filter.marketplace,
        user_id: this.$store.state.user_info.id,
      }

      axios.post('https://tapwill.ru/api/get_abc.php', post_data).then((response)=>{
        this.items = response.data
        this.items.map((item)=>{
          Object.keys(item).forEach(key => {
            if(item[key] == null) item[key] = 0
          });
        })
        console.log(response.data)
        this.loading = false
    })
    },
    applyFilter(){
      this.loadData($('.js-daterangepicker').data('daterangepicker').startDate.format('YYYY-MM-DD'),  $('.js-daterangepicker').data('daterangepicker').endDate.format('YYYY-MM-DD'))
    },
    sortItems(){
      this.loading = true
      if(this.sort.order == 'DESC') this.items.sort((a, b) => parseFloat(b[this.sort.type]) - parseFloat(a[this.sort.type]))
      if(this.sort.order == 'ASC') this.items.sort((a, b) => parseFloat(a[this.sort.type]) - parseFloat(b[this.sort.type]))
      console.log(this.sort)
      this.loading = false
    }
  },
  created(){
    this.loadData(moment().subtract(30, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
    axios.post('https://tapwill.ru/api/get_store.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
      this.stores = response.data
      console.log(this.stores)
    })
    axios.post('https://tapwill.ru/api/get_brands.php', {user_id: this.$store.state.user_info.id}).then((response)=>{
      this.brands = response.data
      console.log(this.brands)
    })
    
  },
  mounted(){
    let start = moment().subtract(30, 'days');
    let end = moment();

    function cb(start, end) {
        $('.js-daterangepicker span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    }

    $('.js-daterangepicker').daterangepicker({
      "locale": {
        "format": "DD.MM.YYYY",
        "separator": " - ",
        "applyLabel": "Сохранить",
        "cancelLabel": "Назад",
        "daysOfWeek": [
            "Вс",
            "Пн",
            "Вт",
            "Ср",
            "Чт",
            "Пт",
            "Сб"
        ],
        "monthNames": [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"
        ],
        "firstDay": 1
    },
      
      opens: 'left',
      autoUpdateInput: true,
      startDate: start,
      endDate: end,
      ranges: {
           'Сегодня': [moment(), moment()],
           'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
           'Последние 7 дней': [moment().subtract(6, 'days'), moment()],
           'Последние 30 дней': [moment().subtract(29, 'days'), moment()],
           'Этот месяц': [moment().startOf('month'), moment().endOf('month')],
           'Прошлый месяц': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
      }, (start, end) => {
        $('.js-daterangepicker').data('daterangepicker').startDate = start
        $('.js-daterangepicker').data('daterangepicker').endDate = end
        this.loadData(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
      }, cb);


    cb(start, end);

    $('li[data-range-key="Custom Range"]').text('Произвольно')
  }
}
</script>